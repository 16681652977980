import eventSaga from './eventSaga'
import loginSaga from './loginSaga'
import studentSaga from './studentSaga'
import { fork,all } from 'redux-saga/effects'

function* rootSaga() {
    yield all([
        fork(loginSaga),
        fork(eventSaga),
        fork(studentSaga),
    ])
}

export default rootSaga