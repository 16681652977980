import * as types from "../types";

// Initial (starting) state
export const initialCommon = {
    isFetching: false,
    errorMsg: "",
};

export const commonReducer = (state = initialCommon, action) => {
    switch (action.type) {
        case types.FETCHING_DATA:
            return {
                ...state,
                isFetching: true,
                errorMsg: "",
            }
        case types.FETCHING_DATA_DONE:
            return {
                ...state,
                isFetching: false,
            }
        case types.FETCHING_DATA_DONE_FAIL:
            return {
                ...state,
                isFetching: false,
                errorMsg: action.msg,
            }
        default:
            return state;
    }
};