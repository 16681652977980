import React from 'react'
import { Redirect } from 'react-router-dom'
import { isLogin } from '../../common/function/auth'
import url from '../../common/data/url.json'

class ProtectedRoute extends React.Component {
    render() {
        const Component = this.props.component;
        // Thứ tự xét ưu tiên
        // only public -> role -> private
        // Path sẽ redrict đến
        let Path = url.Login;

        // Biến flag để nhận biết component chỉ hiển thị khi user chưa login
        let OnlyPublic = this.props.onlyPublic !== undefined ? this.props.onlyPublic : false;

        // Lấy các thông số để authorize
        let Private = this.props.private !== undefined ? this.props.private : false;

        // Biến flag để authorize, false khi authorize false
        let isAuthenticated = true;

        // Kiểm tra only public trước
        if (OnlyPublic) {
            if (isLogin()) {
                isAuthenticated = false;
                Path = url.Main;
            }
        }
        // Kiểm tra private vì role đã bao hàm ràng buộc private = true phía trên
        else if (Private) {
            // Kiểm tra login hay chưa (authen)
            if (!isLogin()) isAuthenticated = false;
        }

        return isAuthenticated ? (
            <Component {...this.props} />
        ) : (
                <Redirect to={{ pathname: Path }} />
            );
    }
}

export default ProtectedRoute;