import { put, takeLatest, call, select } from 'redux-saga/effects'
import * as types from '../types'
import { getListStudent, checkin } from '../../services/checkin.service'
import { signOut } from '../../common/function/auth'
import url from '../../common/data/url.json'

export const getStateStudent = (state) => state.student
const audio = new Audio("/assets/vinh.mp3")

function* getStudent(action) {
    try {
        yield put({ type: types.FETCHING_DATA })
        const res = yield call(getListStudent, action.id)
        yield put({ type: types.FETCHING_DATA_DONE })
        const result = res.data;
        if (result !== null && res.data !== null) {
            yield put({ type: types.GET_STUDENT_SUCCESS, data: res.data })
        }
        else {
            yield put({ type: types.FETCHING_DATA_DONE_FAIL, msg: "Tài khoản có thể bị đăng nhập ở nơi khác, vui lòng đăng nhập lại" })
            signOut()
            action.history.push(url.Login)
        }
    }
    catch (e) {
        yield put({ type: types.FETCHING_DATA_DONE_FAIL, msg: e + "" })
    }
}

function* checkinStudent(action) {
    try {
        audio.play()
        yield put({ type: types.FETCHING_DATA })
        const res = yield call(checkin, action.id, action.code)
        const result = res.data;
        yield put({ type: types.FETCHING_DATA_DONE })

        if (result.Status === 1) {
            let studentState = yield select(getStateStudent);
            let oldList = studentState.data
            let newList = oldList
            if (oldList.find(student => student !== undefined && student.StudentCode === action.code) === undefined) {
                newList = [result].concat(oldList)
            }
            yield put({ type: types.CHECKIN_STUDENT_SUCCESS, data: newList, msg: "Checkin sinh viên " + action.code + " thành công" })
        }
        else {
            yield put({ type: types.CHECKIN_STUDENT_FAIL, msg: "Checkin sinh viên " + action.code + " thất bại" })
        }
    }
    catch (e) {
        yield put({ type: types.CHECKIN_STUDENT_FAIL, msg: "Lỗi " + e })
    }
}

function* studentSaga() {
    yield takeLatest(types.GET_STUDENT, getStudent);
    yield takeLatest(types.CHECKIN_STUDENT, checkinStudent);
}

export default studentSaga;