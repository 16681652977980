import * as types from "../types";

// Initial (starting) state
export const initialStudent = {
    data: [],
    checkinStatus: null,
    checkinMsg: "",
};

export const studentReducer = (state = initialStudent, action) => {
    switch (action.type) {
        case types.GET_STUDENT_SUCCESS:
            return {
                ...state,
                data: action.data,
                checkinStatus: null,
                checkinMsg: "",
            }
        case types.CHECKIN_STUDENT_SUCCESS:
            return {
                ...state,
                data: action.data,
                checkinStatus: true,
                checkinMsg: action.msg,
            }
        case types.CHECKIN_STUDENT_FAIL:
            return {
                ...state,
                checkinStatus: false,
                checkinMsg: action.msg,
            }
        default:
            return state;
    }
};