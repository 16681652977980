// Common type
export const FETCHING_DATA = "FETCHING_DATA" 
export const FETCHING_DATA_DONE = "FETCHING_DATA_DONE"
export const FETCHING_DATA_DONE_FAIL = "FETCHING_DATA_DONE_FAIL"
export const LOGOUT = "LOGOUT" 

// Login type
export const DO_LOGIN = "DO_LOGIN" 
export const LOGIN_SUCCESS = "LOGIN_SUCCESS" 
export const LOGIN_FAIL = "LOGIN_FAIL"

// Get event type
export const GET_EVENT = "GET_EVENT" 
export const GET_EVENT_SUCCESS = "GET_EVENT_SUCCESS" 
export const GET_EVENT_FAIL = "GET_EVENT_FAIL" 

// Get student type
export const GET_STUDENT = "GET_STUDENT" 
export const GET_STUDENT_SUCCESS = "GET_STUDENT_SUCCESS" 
export const GET_STUDENT_FAIL = "GET_STUDENT_FAIL" 

// Get student type
export const CHECKIN_STUDENT = "CHECKIN_STUDENT" 
export const CHECKIN_STUDENT_SUCCESS = "CHECKIN_STUDENT_SUCCESS" 
export const CHECKIN_STUDENT_FAIL = "CHECKIN_STUDENT_FAIL"