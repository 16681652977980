import { commonReducer, initialCommon } from "./commonReducer";
import { eventReducer, initialEvent } from "./eventReducer";
import { studentReducer, initialStudent } from "./studentReducer";

import { combineReducers } from "redux";

// Define list reducers.
export const reducers = combineReducers({
    common: commonReducer,
    event: eventReducer,
    student: studentReducer,
})

// Define initial reducers.
export const initReducers = {
    common: initialCommon,
    event: initialEvent,
    student: initialStudent,
}