import { call, put, takeEvery } from 'redux-saga/effects'
import { setAuth, signOut } from '../../common/function/auth'
import { doLogin } from '../../services/login.service'
import * as types from '../types'
import url from '../../common/data/url.json'

function* doLoginSaga(action) {
    try {
        yield put({ type: types.FETCHING_DATA })
        const res = yield call(doLogin, action.username, action.password)
        const result = res.data;
        //yield put({ type: types.FETCHING_DATA_DONE })

        if (result.Success) {
            setAuth(result.Fullname, result.Token, 1)
            yield put({ type: types.LOGIN_SUCCESS })
            action.history.push(url.Main);
        }
        else {
            yield put({ type: types.FETCHING_DATA_DONE_FAIL, msg: result.Message })
        }
    }
    catch (e) {
        yield put({ type: types.FETCHING_DATA_DONE_FAIL, msg: e+"" })
    }
}

function* doLogout(action) {
    yield signOut()
}

function* loginSaga() {
    yield takeEvery(types.DO_LOGIN, doLoginSaga);
    yield takeEvery(types.LOGOUT, doLogout);
}

export default loginSaga;