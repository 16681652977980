import { put, takeEvery } from 'redux-saga/effects'
import * as types from '../types'

// function* changeLanguage(action) {

// }

function* eventSaga() {
    //yield takeEvery(types, changeLanguage);
}

export default eventSaga;