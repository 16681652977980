import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch } from "react-router-dom";
import ProtectedRoute from './components/ProtectedRoute';
import url from './common/data/url.json'
import LinearProgress from '@material-ui/core/LinearProgress';
import 'fontsource-roboto';
import configureStore from "./redux";
import { Provider } from "react-redux";
import history from './common/function/history'
const LoginScreen = lazy(() => import('./screens/Login'));
const MainScreen = lazy(() => import('./screens/Main'));
const CheckinScreen = lazy(() => import('./screens/Checkin'));
const store = configureStore();

ReactDOM.render(
  <div onContextMenu={(e) => e.preventDefault()}>
    <Provider store={store}>
      <Router history={history}>
        <Suspense fallback={<LinearProgress />}>
          <Switch>
            {/* Trang đăng nhập */}
            <ProtectedRoute exact onlyPublic={true} path={url.Login} component={LoginScreen} />

            {/* Trang main */}
            <ProtectedRoute exact private={true} path={url.Main} component={MainScreen} />

            {/* Trang checkin */}
            <ProtectedRoute exact private={true} path={url.Checkin} component={CheckinScreen} />

            {/* Trang mặc định */}
            <ProtectedRoute onlyPublic={true} extact path="/" component={LoginScreen} />
          </Switch>
        </Suspense>
      </Router>
    </Provider>
  </div>,
  document.getElementById('root')
);