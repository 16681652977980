import setting from '../common/data/url.json'
import { getUser } from '../common/function/auth'
const axios = require('axios');

export function getListStudent(id) {
    var url = setting.Host + setting.ApiGetStudentEvent.replace('{token}', getUser().Token).replace('{id}', id);
    return axios.post(url);
}

export function checkin(id, studentCode) {
    var url = setting.Host + setting.ApiCheckin.replace('{token}', getUser().Token);
    return axios.post(url, {
        IdEvent: id,
        StudentCode: studentCode
    });
}

export function checkout(id, studentCode) {
    var url = setting.Host + setting.ApiCheckout.replace('{token}', getUser().Token);
    return axios.post(url, {
        IdEvent: id,
        StudentCode: studentCode
    });
}

export function deleteCheckin(id, studentCode) {
    var url = setting.Host + setting.ApiDelete.replace('{token}', getUser().Token);
    return axios.post(url, {
        IdEvent: id,
        StudentCode: studentCode
    });
}