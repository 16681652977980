import { createStore, applyMiddleware } from "redux";
import { reducers, initReducers } from './reducers'
import createSagaMiddleware from 'redux-saga'
import rootSaga from './sagas'

// Create the saga middleware.
const sagaMiddleware = createSagaMiddleware()

// Define Store.
export const configureStore = () => {
    const store = createStore(
        reducers,
        initReducers,
        applyMiddleware(sagaMiddleware)
    );

    // Run the saga
    sagaMiddleware.run(rootSaga)

    return store;
};
export default configureStore;