// Định nghĩ các biến cần thiết
// Biến lưu key của local storage
var _UserKey = "hutechcheckin";

// Set auth
export function setAuth(name, token, role) {
    // Khởi tạo user mới
    let user = new CurrentUser(name, token, role);

    // Lưu json
    let json = JSON.stringify(user);

    // Lưu vào localStorage
    localStorage.setItem(_UserKey, json);
}

// Kiểm tra đã login chưa
export function isLogin() {
    let json = localStorage.getItem(_UserKey);
    if (!json) return false;

    try {
        if (getUser() === null) return false;
        return true;
    }
    catch {
        signOut();
        return false;
    }
}

// Lấy ra đối tượng
export function getUser() {
    try {
        let json = localStorage.getItem(_UserKey);
        let user = JSON.parse(json);
        return user;
    }
    catch {
        signOut();
        return null;
    }
}

// Kiểm tra role
export function isRole(checkRole) {
    let user = getUser();
    if (user === null) return false;
    return user.Role === checkRole;
}

// Clear toàn bộ dữ liệu đang lưu
export function signOut() {
    localStorage.removeItem(_UserKey);
}

// Model của auth
function CurrentUser(name, token, role) {
    this.Name = name;
    this.Token = token;
    this.Role = role;
}