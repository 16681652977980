import * as types from "../types";

// Initial (starting) state
export const initialEvent = {
    data: [],
};

export const eventReducer = (state = initialEvent, action) => {
    switch (action.type) {
        default:
            return state;
    }
};